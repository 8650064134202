import PropTypes from 'prop-types';
// @mui
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from '@mui/material';
// components
import Label from '../label';
import Scrollbar from '../scrollbar';
import {TableHeadCustom} from './index.js';
import moment from "moment/moment";
import SouthWestRoundedIcon from '@mui/icons-material/SouthWestRounded';
import NorthEastRoundedIcon from '@mui/icons-material/NorthEastRounded';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';

import MoneyHelper from "../../Helpers/MoneyHelper";
import OSButton from "../Button";
import React, {useState} from "react";
import OsModal from "../Modals";
import CgpNetwork from "../../config/Network/CgpNetwork.ts";
import {toast} from "react-toastify";

import './myTable.scss';
import NumberHelper from "../../Helpers/NumberHelper";

// ----------------------------------------------------------------------

MyTable.propTypes = {
  title: PropTypes.string,
  tableData: PropTypes.array,
  subheader: PropTypes.string,
  tableLabels: PropTypes.array,
};

export default function MyTable({ title, subheader, tableData, tableLabels, ...other }) {
  return (
    <Card {...other}>
      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar>
          <Table sx={{ minWidth: 720 }}>
            <TableHeadCustom headLabel={tableLabels} />
            <TableBody>
              {tableData.map((row, index) => row.transaction_type !== 'fees' && (
                <MyTableRow key={`tableCell${index}-${row.created_at}-${row.notes}`} row={row} {...other}/>
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
    </Card>
  );
}

// ----------------------------------------------------------------------

MyTableRow.propTypes = {
  row: PropTypes.shape({
    title: PropTypes.string,
    amount: PropTypes.string,
    status: PropTypes.string,
    date: PropTypes.string,
  }),
};

const checkRowType = transaction => {
  let title;
  let growth;
  let amount = MoneyHelper.formatEuro(transaction.amount);
  if (transaction.transaction_type === 'withdraw') {
    title = 'Retrait';
    growth = <div className="arrow-container arrow-container-red"><SouthWestRoundedIcon /></div>;
  } else if (transaction.transaction_type === 'top_up') {
    title = 'Dépôt';
    growth = <div className="arrow-container arrow-container-green"><NorthEastRoundedIcon /></div>;
  } else if (transaction.transaction_type === 'interest_from_base_rate') {
    title = 'Intérêts';
    growth = <div className="arrow-container arrow-container-green"><NorthEastRoundedIcon /></div>;
    amount = MoneyHelper.formatEuro(transaction.amount, false, 4, 4);
  } else if (transaction.transaction_type === 'interest_from_boosts') {
    title = 'Intérêts boost';
    growth = <div className="arrow-container arrow-container-green"><NorthEastRoundedIcon /></div>;
    amount = MoneyHelper.formatEuro(transaction.amount, false, 4, 4);
  } else if (transaction.transaction_type === 'gift_in_euro') {
    title = 'Autres primes';
    growth = <div className="arrow-container arrow-container-green"><NorthEastRoundedIcon /></div>;
  } else if (transaction.transaction_type === 'referral_bonus') {
    title = 'Prime de parrainage';
    growth = <div className="arrow-container arrow-container-green"><NorthEastRoundedIcon /></div>;
  } else if (transaction.transaction_type === 'referred_bonus') {
    title = 'Prime de bienvenue';
    growth = <div className="arrow-container arrow-container-green"><NorthEastRoundedIcon /></div>;
  } else if (transaction.transaction_type === 'taxes') {
    title = 'Prélèvements et impôts';
    growth = <div className="arrow-container arrow-container-red"><SouthWestRoundedIcon /></div>
    amount = MoneyHelper.formatEuro(transaction.amount, false, 2, 2);
  } else if (transaction.transaction_type === 'transfer') {
    title = 'Transfert';
    growth = <div className="arrow-container arrow-container-info"><CachedOutlinedIcon /></div>
  } else if (transaction.transaction_type === 'cgp_entry_fees') {
    title = 'Commissions';
    growth = <div className="arrow-container arrow-container-info"><CachedOutlinedIcon /></div>
  }
  return {title, growth, amount};
};

const checkRowStatus = (transaction, other) => {
  const livretp = window.location.href.includes("livret");
  let status;
  if (transaction.status === 'success') {
    status = 'Validé';
  } else if (transaction.status === 'error') {
    status = 'Erreur';
  } else if (transaction.status === 'pending') {
    if (livretp) {
      status = 'En cours';
    } else {
      status = other.cgp ? "En attente de réception des fonds" : 'En cours';
    }
  } else if (transaction.status === 'pending_scpi') {
    status = 'En attente';
  } else if (transaction.status === 'potential') {
    status =  'En cours';
  } else if (transaction.status === 'not_started') {
		if (other.cgp) {
			status = "En attente de signature"
		} else {
			status = "Paiement à réaliser"
		}
  }
  return status;
};

function MyTableRow({ row, ...other }) {
	const [isShowing, setIsShowing] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const livretp = window.location.href.includes("livret");

	async function sendReminderAction() {
		setSubmitting(true);

		const response = await CgpNetwork.reSendSignature(row.ys_signature_request_id);
		if (response.status === 200) {
			toast.success(response.message, {
				toastId: `reSentDocument`
			});
			setIsShowing(false)
		} else if (response.status === 401) {
			toast.error(response.message, {
				toastId: 'reSentDocument-error'
			});
		} else {
			toast.error(`Un problème est survenu lors de l'envoi, si le problème persiste veuillez contacter nos équipes.`,{
				toastId: `error-sentDocument`
			})
		}
		setSubmitting(false);
	}

	const checkPaiementLink = (row) => {
		localStorage.setItem('InvestmentAmount', row.amount)
		if (livretp) {
			return `https://app.openstone.com/opportunites/livret-p/deposit?transaction_id=${row.id}&amount=${row.amount}`
		} else {
			return `https://app.openstone.com/opportunites/${row.product?.category_slug}/${row.product?.slug}/finalise?transaction_id=${row.id}`
		}
	}


	return (
    <>
	    {row.transaction_type !== 'cgp_entry_fees' && (
		    <TableRow>
			    <TableCell>
				    <div style={{display: "flex", alignItems: 'center', gap: 10}}>
					    {checkRowType(row).growth}
					    <span className="ds_category_name">{checkRowType(row).title}</span>
				    </div>
			    </TableCell>

			    <TableCell>
				    <span className="ds_category_name">{moment(row.created_at, 'YYYY-MM-DD').format('DD/MM/yyyy')}</span>
			    </TableCell>

			    <TableCell>
				    <span className="ds_category_name">{checkRowType(row).amount}</span>
			    </TableCell>

			    <TableCell>
				    <Label
					    variant="soft"
					    color={
						    ((row.status === 'pending' || row.status === 'pending_scpi' || row.status === 'potential') && 'info') ||
						    (row.status === 'error' && 'error') || (row.status === 'not_started' && 'warning') ||
						    'success'
					    }
				    >
					    {checkRowStatus(row, other)}
				    </Label>
			    </TableCell>

			    <TableCell>
				    <div className="os-flex gap-16">
					    { (row?.related_doc !== null && row?.transaction_type === 'top_up') && <OSButton link={row?.related_doc?.document?.url} variant="no_border-info" fit size="small" title="Consulter le bulletin" target/>}
					    { (other.cgp && (row?.status === 'not_started' && row?.transaction_type === 'top_up')) && <OSButton onClick={() => setIsShowing(true)} variant="no_border-info" fit size="small" title="Envoyer un rappel" /> }
					    { (!other.cgp && (row.status === 'not_started' || row.status === 'error')) && (
						    <OSButton title="Effectuer le paiement" variant="primary-full" fit size="small" link={checkPaiementLink(row)} />
					    )}
				    </div>
			    </TableCell>
		    </TableRow>
	    )}
	    {row.associated_fees && (
		    <TableRow>
			    <TableCell>
				    <div style={{display: "flex", alignItems: 'center', gap: 10}}>
					    {checkRowType(row.associated_fees).growth}
					    <span className="ds_category_name">{checkRowType(row.associated_fees).title}</span>
				    </div>
			    </TableCell>

			    <TableCell>
				    <span className="ds_category_name">{moment(row.associated_fees.created_at, 'YYYY-MM-DD').format('DD/MM/yyyy')}</span>
			    </TableCell>

			    <TableCell>
				    <span className="ds_category_name">{checkRowType(row.associated_fees).amount}</span>
			    </TableCell>

			    <TableCell>
				    <Label
					    variant="soft"
					    color={
						    ((row.associated_fees.status === 'pending' || row.associated_fees.status === 'pending_scpi' || row.associated_fees.status === 'potential') && 'info') ||
						    (row.associated_fees.status === 'error' && 'error') || (row.associated_fees.status === 'not_started' && 'warning') ||
						    'success'
					    }
				    >
					    {checkRowStatus(row.associated_fees, other)}
				    </Label>
			    </TableCell>
			    <TableCell />
		    </TableRow>
	    )}
	    <OsModal title="Confirmer le nouvel envoi de signature éléctronique" isShowing={isShowing} hide={() => setIsShowing(false)} >
		   <>
			   <p>L'action suivante enverra à nouveau la demande de signature pour cette investissement, voulez-vous continuer ?</p>
			   <div className="os-flex gap-16 modal-btns-container">
				   <OSButton onClick={() => sendReminderAction()} title="Fermer" variant="primary-full" size="large" submitting={submitting} />
				   <OSButton onClick={() => sendReminderAction()} title="Envoyer" variant="primary" size="large" />
			   </div>
		   </>
	    </OsModal>
    </>
  );
}
