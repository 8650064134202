import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

import { addAdditionalClasses } from '../../utils/utils';

/**
 * A simple tab component.
 * @param {boolean} clickable - Whether to display the tab with a clickable behavior.
 * @param {boolean} selected - Whether to display the tab with the selected behavior.
 * @param {boolean} disabled - Whether to display the tab with the disbaled behavior.
 */
const CustomTab = ({ tab, clickable, onTabClick, selected, disabled }) => {
  const classNames = addAdditionalClasses('os-flex os-tab', [
    clickable && 'os-tab--clickable',
    selected && 'os-tab--selected',
    disabled && 'os-tab--disabled'
  ]);

  function handleTabClick(id) {
    onTabClick(id)
  }

  return (
    <div className={classNames} onClick={() => clickable && handleTabClick(tab.id)}>
      {tab?.img}
      <div className="os_tab__infos">
        <div className="os-tab__title">
          <p className="os-subtitle1">{tab?.title}</p>
          <p className="os-body2">{tab?.description}</p>
        </div>
      </div>
    </div>
  );
};

CustomTab.propTypes = {
  clickable: PropTypes.bool,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
};

CustomTab.defaultProps = {
  clickable: false,
  selected: false,
  disabled: false,
};

export default CustomTab;
