import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {useLocation} from "react-router";
import {Link, useNavigate} from "react-router-dom";

import AuthSignupForm from "../../../Forms/Auth/SignUp";

import Images from "../../../assets/images";

import PublicNav from "../../../components/Navbar/publicNav";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import Info from "../../../components/info";

import authenticationStore from "../../../stores/AuthenticationStore.ts";
import Phone from "../../../components/Phone";
import images from "../../../assets/images";

import { ReactComponent as LogoGreen } from '../../../components/Navbar/logoGreen.svg';

import OSButton from "../../../components/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import '../style.scss';
import Footer from "../../../components/Footer";

const elements = [
  {img: Images.cgp.accesibility, content: <p  >Produits accessibles à partir de <br/><span className="important">1 000 € contre 100 000 €</span> en direct</p>},
  {img: Images.cgp.growth, content: <p>Rentabilité de <span className="important">+10 %</span></p>},
  {img: Images.cgp.speed, content: <p>Souscription en ligne ici<br/>(gain de temps par rapport au format papier)</p>},
  {img: Images.cgp.independant, content: <p><span className="important">Indépendance</span></p>},
];

const SignUp = () => {
	const location = useLocation();
  const navigate = useNavigate();
  const authToken = localStorage.getItem('UserAuthToken');
  const [otp, setOtp] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [redirectUrl, setRedirectUrl] = useState('/overview');
	const [affiliateLink, setAffiliateLink] = useState(null);

  async function fetchUser() {
    await authenticationStore.loadUserData().then(response => {
      setUser(response);
      setLoading(false);
    });
  }

  useEffect(() => {
    otp && fetchUser();
  }, [otp]);

  useEffect(() => {
    if (!otp && authToken && authToken !== 'undefined') {
      navigate('/overview')
    }
  }, [authToken, navigate]);
	
	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const url = searchParams.get('redirectUrl');
		const affiliate = searchParams.get('affiliateLink');
		
		url && setRedirectUrl(url);
		affiliate && setAffiliateLink(affiliate);
	}, [location, navigate]);

  return (
    <>
      <Helmet>
        <title>Authentification</title>
      </Helmet>
      <PublicNav />
      <div className="os-flex collab-container">
        <div className="collab-left-container image-section-sign-up">
          <div className="sub-container">
            <h3 className="os-h3" style={{fontSize: 28, color:"white"}}>Pourquoi investir sur Openstone ?</h3>
            <div className="avantages-container">
              {elements.map((e, index) => (
                <div className="os-flex avantage-element" key={index}>
                  <img src={e.img} alt=""/>
                  <span className="os-body1">{e.content}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="collab-auth-container">
          <LogoGreen className="auth-os-logo"/>
          <div className="collab-auth-card top-not-cgp">
            {(otp && !loading) ? (
              <>
                <h4 className="os-h4">Vérifier votre numéro de téléphone</h4>
                <span className="os-caption signup-text">Pour la sécurité de votre compte, veuillez vérifier votre numéro de de téléphone</span>
                <Phone user={user} redirectUrl={redirectUrl !== '' ? redirectUrl : '/overview'} page="signUp" />
              </>
            ) : (
              <>
                <div className="os-flex__column top-container">
                  <h4 className="os-h4">Inscrivez-vous sur Openstone</h4>
                  <div className="os-flex top-form">
                    <span className="os-caption signup-text">Déjà inscrit ?
                      <Link to="/sign-in" className="signup-link"> Connectez-vous !</Link>
                    </span>
                    <OSButton variant="primary-full" size="large" title="Vous êtes partenaire ?" fit link="/cgp" icon={<ArrowForwardIcon />} position="end"/>
                  </div>
	                <Info logo={<LightbulbIcon/>} children="Titulaire d'un compte Livret P. ? Connectez-vous avec votre numéro de téléphone associé."/>
                </div>
                <AuthSignupForm setPhone={setOtp} collaborator={affiliateLink}/>
              </>
            )}
          </div>
          <div className="labels">
            <img src={images.brand.labels} alt="Labels Openstone" />
          </div>
        </div>
      </div>
	    <Footer />
    </>
  );
};

export default SignUp;

