import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router";

import { ReactComponent as LogoGreen } from '../../../components/Navbar/logoGreen.svg';
import AccountCardSelection from "../../../components/AccountSelection";
import images from "../../../assets/images";

import './style.scss';
import Footer from "../../../components/Footer";

const AccountSelection = () => {
		const location = useLocation();
		const [redirectUrl, setRedirectUrl] = useState('');
	  const [affiliateLink, setAffiliateLink] = useState(null);
		
		useEffect(() => {
			const searchParams = new URLSearchParams(location.search);
			const url = searchParams.get('redirectUrl');
			const affiliate = searchParams.get('affiliateLink');
			
			url && setRedirectUrl(url);
			affiliate && setAffiliateLink(affiliate);
		}, [location]);
	
		const constructLink = (baseLink) => {
			let link = baseLink;
			const hasQueryParams = baseLink.includes('?');
			const separator = hasQueryParams ? '&' : '?';
			if (affiliateLink) {
				link += `${separator}affiliateLink=${affiliateLink}`;
			}
			return link;
		};
		
    return (
			<>
        <div className="container">
            <div className="image-section"/>
            <div className="selection-section">
                <LogoGreen className="auth-os-logo"/>
                <div className="os-flex__column account-selection-container">
                  <p className="os-subtitle1 selection-title">Quel est votre profil ?</p>
                  <div className="os-flex card-selection-container gap-24">
                    <AccountCardSelection tittle="Espace Particulier"
                                          subtitle="Continuer"
                                          img={images.particulier}
                                          link={redirectUrl !== '' ? constructLink(`/sign-up?productUrl=${redirectUrl}`) : constructLink('/sign-up')}/>
                    <AccountCardSelection tittle="Espace Partenaire" subtitle="Continuer" img={images.partner} link="/sign-up/cgp"/>
                  </div>
                </div>
                <div className="labels">
                    <img src={images.brand.labels} alt="Labels Openstone" />
                </div>
            </div>
        </div>
			  <Footer />
			</>
    );
};

export default AccountSelection;
