import React, {useEffect, useState} from 'react';
import { Formik, Form } from 'formik';

import {MyTextInput} from "../../Inputs";
import MoneyHelper from "../../../Helpers/MoneyHelper";
import NumberHelper from "../../../Helpers/NumberHelper";

import './style.scss';

const DepositForm = ({product, page, func, pullData, isClicked, minimumInvestment, cgp}) => {
  const [amount, setAmount] = useState(((page !== 'opportunityView' || page !== 'opportunityStep1') && localStorage.getItem('InvestmentAmount')) || null);
  const [cgpEntryFees, setCgpEntryFees] = useState(((page !== 'opportunityView' || page !== 'opportunityStep1') && localStorage.getItem('CgpEntryFees')) || null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [errorCgpEntryFees, setErrorCgpEntryFees] = useState('');
	const [total, setTotal] = useState(null);
	
	const updateAmount = (value) => {
		let minimum = parseInt(minimumInvestment)
		if (value === '' || value === null || value === 0) {
			setError('Un montant doit être renseigné');
		} else if (value < minimum) {
			setError(`L'investissement doit être au minimum de ${MoneyHelper.formatEuro(minimum, false, 0, 0)}`);
		} else {
			setError('');
		}
		setAmount(value);
	};
	
	const updateCgpEntryFees = (fees) => {
	  if (fees < 0 ) {
			setErrorCgpEntryFees('Doit être entre 0 et 2,5%')
	  } else if (fees > 2.5) {
		  setErrorCgpEntryFees('Doit être entre 0 et 2,5%')
	  } else {
			setErrorCgpEntryFees('')
			setCgpEntryFees(fees)
	  }
	}
	
	useEffect(() => {
		if (amount !== null && cgpEntryFees !== null) {
			setTotal(amount * ((cgpEntryFees / 100) + 1))
		}
	}, [amount, cgpEntryFees]);


  return (
    <>
      <Formik
        initialValues={{
          amount: amount,
	        cgpEntryFees: cgpEntryFees
        }}
        onSubmit={ async (values ) => {
          setIsLoading(true);
          if (page === 'opportunityView') {
            if (amount !== null) {
              localStorage.setItem('InvestmentAmount', amount)
              const currentUrl = new URL(window.location.href);
              currentUrl.pathname += '/finalise';
              window.location.replace(currentUrl.toString());
            } else {
              setError("Vous devez entrer un montant")
            }
          } else if (page === 'opportunityView' && product.name === 'Livret P.') {
            if (amount !== null) {
              window.location.href = `/opportunites/livret-p/deposit${`?amount=${amount}`}`
            } else {
              setError("Vous devez entrer un montant")
            }
          } else if (page === 'opportunityStep1') {
						if (cgpEntryFees !== null) {
							if (total !== null) {
								localStorage.setItem('InvestmentAmount', amount)
								localStorage.setItem('CgpEntryFees', cgpEntryFees)
								func(true)
							}
						} else {
							setAmount(amount);
							if (amount !== null) {
								localStorage.setItem('InvestmentAmount', amount)
								func(true)
							}
						}
          } else if (page === 'opportunityStep2') {
	          if (cgpEntryFees !== null) {
		          if (total !== null) {
			          localStorage.setItem('InvestmentAmount', amount)
			          cgp && localStorage.setItem('CgpEntryFees', cgpEntryFees)
			          func()
		          }
	          } else {
							if (cgp) {
								localStorage.setItem('InvestmentAmount', amount)
								func()
							} else {
								setAmount(amount);
								amount !== null && localStorage.setItem('InvestmentAmount', amount)
								pullData(amount, true);
							}
	          }
          }
          setIsLoading(false);
        }}
      >
        <Form className="complete-infos__container">
          <>
	          <h5 className="os-subtitle1">Montant de la souscription</h5>
            <MyTextInput
              name="amount"
              type="number"
              placeholder={`${minimumInvestment !== undefined && NumberHelper.numberWithSpaces(minimumInvestment)} €`}
              isClicked={isClicked}
              value={amount}
              onChange={(e) => updateAmount(e.target.value)}
            />
            {error !== '' && <p className="error">{error}</p>}
          </>
	        
	        {(cgp && (page === 'opportunityStep1' || page === 'opportunityStep2')) && (
		        <>
			        <h5 className="os-subtitle1">Votre commission de souscription</h5>
			        <span className="os-caption" style={{marginBottom: 8}}>Les droits d’entrée versés par le souscripteur s’élèvent à 2,5% maximum du montant de souscription</span>
			        <MyTextInput
				        name="cgpEntryFees"
				        type="number"
				        placeholder={'0-2,5%'}
				        isClicked={isClicked}
				        value={cgpEntryFees}
				        onChange={(e) => updateCgpEntryFees(e.target.value)}
			        />
			        {errorCgpEntryFees !== '' && <p className="error">{errorCgpEntryFees}</p>}
			        
			        {total !== null && <p className="os-body1 info-text">Montant total à régler par le client (Investissement + votre commission): <strong>{MoneyHelper.formatEuro(total, false, 0, 0)}</strong></p>}
		        </>
	        )}
	        
	        
          <div className="submit-container">
            <button className="submit-btn" style={{width: '100%', marginTop: 8}} type="submit" disabled={isLoading || error !== '' || errorCgpEntryFees !== ''}>
	            Continuer la souscription
						</button>
          </div>
        </Form>
      </Formik>
    </>
  );
};
export default DepositForm;
