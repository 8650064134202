import APIs from './Network.ts';

class MixedTransctionNetwork extends APIs {
  async updateTransaction(transactionId: string, transactionNature: string, forType: string) {
    return await this.put('/mixed_transactions/transactions', {
      transaction_id: transactionId,
      transaction_nature: transactionNature,
      for_type: forType
    });
  }
}

export default new MixedTransctionNetwork();
