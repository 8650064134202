import Network from './Network.ts';

class BridgeNetwork extends Network {
  async list() {
    return await this.get('/bridge/banks', {});
  }

  async lastTransaction() {
    return await this.get('/bridge/transactions/last', {});
  }

  async initialiseTransaction(bankId: number, amount: number, forType: string, transactionId) {
    return await this.post(`/bridge/transactions/openstone`, {
      bank_id: bankId,
      amount: amount.toString(),
      request_type: 'link',
      for_type: forType,
      transaction_id: transactionId,
    });
  }

  async initialiseProductTransaction(bankId: number, amount: number, productId: number, categoryId: number, transactionId, forType: string) {
    return await this.post('/bridge/products', {
      bank_id: bankId,
      amount: amount.toString(),
      request_type: 'link',
      product_id: productId,
      category_id: categoryId,
      transaction_id: parseInt(transactionId),
      for_type: forType
    });
  }
}

export default new BridgeNetwork();
