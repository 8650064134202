import axios from 'axios';

import constants from '../constants';

const queryString = require('querystring');

const DEFAULT_TIMEOUT = 1000 * 60 * 15;
axios.defaults.baseURL = constants.baseUrl;

class Network {
  get commonOptions() {
    const userToken = localStorage.getItem('UserAuthToken');
    const authToken = userToken ? userToken : localStorage.getItem('CgpUserAuthToken')
    const company = JSON.parse(localStorage.getItem('company'));
    let options = {
      timeout: DEFAULT_TIMEOUT,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authToken,
        'X-Company': company !== '' && company?.id,
      },
    };
    return options;
  }

  get uploadOptions() {
    const authToken = localStorage.getItem('UserAuthToken');
    const cgpAuthToken = localStorage.getItem('CgpUserAuthToken');
    const company = JSON.parse(localStorage.getItem('company'));
    let options = {
      timeout: DEFAULT_TIMEOUT,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authToken || cgpAuthToken,
        'X-Company': company !== '' && company?.id,
      },
    };
    return options;
  }

  async get(url: string, params: any) {
    try {
      const queryStringUrl = `${url}?${queryString.stringify(params, {
        arrayFormat: 'bracket',
      })}`;
      const response = await axios.get(queryStringUrl, {
        params,
        ...this.commonOptions,
      });

      return response ? response?.data : true;
    } catch (err) {
      return false;
    }
  }

  async post(url: string, params: any, wrapResponse: boolean = false) {
    try {
      const response = await axios.post(url, params, {
        ...this.commonOptions,
      });

      if (wrapResponse) {
        return {
          success: true,
          data: response?.data
        };
      }

      return response ? response?.data : true;
    } catch (err) {
      if (wrapResponse) {
        return {
          success: false,
          data: err.response ? err.response.data : { message: 'An error occurred' }
        }
      } else {
        // Return the error response data directly if it exists, otherwise return false
        return err.response ? err.response.data : false;
      }
    }
  }

  async put(url: string, params: any) {
    try {
      const response = await axios.put(url, params, {
        ...this.commonOptions,
      });

      return response ? response?.data : true;
    } catch (err) {
      return false;
    }
  }

  async delete(url: string, params: any) {
    try {
      const response = await axios.delete(url, {
        params,
        ...this.commonOptions,
      });

      return response ? response?.data : true;
    } catch (err) {
      return false;
    }
  }

  async formDataPost(url: string, data: any) {
    const formData = new FormData();
    formData.append('document_type', data.document_type);
    formData.append(data.record_type, data.record_id);
    data.files.map(file => formData.append('files[]', file));
    const response = await axios.post(url, formData, {
      ...this.uploadOptions,
    });

    //console.log('response ', JSON.stringify(response));
    return response;
  }
}

export default Network;
