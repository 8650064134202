import React from "react";

import {ReactComponent as More} from '../icons/ic_more.svg';

import DashboardIcon from '@mui/icons-material/Dashboard';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import SpeedIcon from '@mui/icons-material/Speed';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';

const generalCgpLinks = [
  {
    title: 'Dashboard',
    link: '/cgp/dashboard',
    icon: <DashboardIcon />
  },
  {
    title: 'Gestion clients',
    link: '/cgp/clients',
    icon: <GroupOutlinedIcon />
  },
];

const soonCgpLinks = [
  {
    title: 'Performance',
    link: '#',
    icon: <SpeedIcon />,
  },
  {
    title: 'Campagnes',
    link: '#',
    icon: <CampaignOutlinedIcon />,
  }
]

const desktopCgpLinks = [
  {
    title: 'Toutes les offres',
    link: '/opportunites',
    icon: null
  },
  {
    title: 'BlackRock',
    link: '/opportunites/alternatifs/europe-property-fund-vi',
    icon: null
  },
  {
    title: 'EQT',
    link: '/opportunites/alternatifs/europe-logistics-core-plus-II',
    icon: null
  },
    {
        title: 'Ares',
        link: '/opportunites/alternatifs/european-property-enhancement-partners-IV',
        icon: null
    }
];

const mobileCgpLinks = [
  {
    title: 'Dashboard',
    link: '/cgp/dashboard',
    icon: <DashboardIcon />
  },
  {
    title: 'Gestion clients',
    link: '/cgp/clients',
    icon: <GroupOutlinedIcon />
  },
  {
    title: 'Nos produits',
    link: '/opportunites',
    icon: <More />
  }
];


export { generalCgpLinks, desktopCgpLinks, soonCgpLinks, mobileCgpLinks };
