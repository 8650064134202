import React from 'react';
import {Helmet} from "react-helmet";

import {ReactComponent as LogoGreen} from '../../components/Navbar/logoGreen.svg';
import Phone from "../../components/Phone";
import PublicNav from "../../components/Navbar/publicNav";
import images from "../../assets/images";
import Footer from "../../components/Footer";

import './style.scss';
import OSButton from "../../components/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const CgpLogin = () => {
  return (
    <>
      <Helmet>
        <title>Connexion partenaires / cgp</title>
      </Helmet>
      <PublicNav />
        <div className="container">
          <div className="image-section"/>
          <div className="auth-container">
            <LogoGreen className="auth-os-logo" />
            <div className="auth-phone-container">
              <div className="os-flex__column top-container">
              <div className="os-flex gap-32">
                <h4 className="auth-title os-h4">Connexion partenaire Openstone</h4>
              </div>
              <div className="os-flex top-form">
                  <span className="signup-text">Nouvel utilisateur ?
                    <a href="/sign-up/cgp" className="signup-link">Inscrivez-vous !</a>
                  </span>
                  <OSButton variant="primary-full" size="large" title="Vous êtes un particulier ?" fit link="/sign-in" icon={<ArrowForwardIcon />} position="end"/>
              </div>
                  <Phone user={null} page="auth" recordType="cgp" />
              </div>

            </div>
            <div className="labels">
              <img src={images.brand.labels} alt="Labels Openstone"/>
            </div>
          </div>
        </div>
	    <Footer />
    </>
  );
};

export default CgpLogin;
