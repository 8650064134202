import React, {useEffect, useState} from 'react';
import {MyTextInput} from "../../../Forms/Inputs";
import {Form, Formik} from "formik";
import MoneyHelper from "../../../Helpers/MoneyHelper";

import './style.scss';
import {useSearchParams} from "react-router-dom";
import {parseInt} from "lodash";

const SetAmount = ({func, page, user}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [amount, setAmount] = useState(parseInt(searchParams.get('amount')) || '');
  const [showError, setShowError] = useState(false);
  const maxAmount = parseFloat(user?.withdrawable_amount?.replace(',', '.'));

  useEffect(() => {
    let newAmount = parseFloat(amount)
    switch (page) {
      case 'withdraw':
       if (newAmount > maxAmount || isNaN(newAmount)) {
          func(0, false)
          setShowError(true);
        } else if (newAmount <= maxAmount) {
           if (newAmount <= 20000) {
              func(newAmount, true)
              setShowError(false);
           } else {
             func(0, false);
             setShowError(true)
           }
        } else if (newAmount === 0) {
          func(0, false)
          setShowError(true);
        }
       break;
       default:
         if (newAmount < 100 || isNaN(newAmount)) {
           func(0, false)
           setShowError(true);
         } else if (newAmount > 10000 || isNaN(newAmount)) {
           func(0, false)
           setShowError(true);
         } else if (100 <= newAmount && newAmount <= 10000) {
           func(newAmount, true)
           setShowError(false);
         } else {
           func(0, false)
           setShowError(true);
         }
    }
  }, [amount, func])

  function renderError() {
    if (page === 'withdraw') {
      return (
        <span className="error">
          Vous ne pouvez retirer que le montant disponible à savoir : {MoneyHelper.formatEuro(maxAmount, false, 4, 4)}
        </span>
      )
    } else {
      return (
        <span className="error">
          Doit être entre {MoneyHelper.formatEuro(100)} et {MoneyHelper.formatEuro( 10000 - maxAmount, false, 4, 4)}
        </span>
      )
    }
  }

  return (
    <Formik initialValues={{amount: amount}}>
      <Form className="complete-infos__container">
        <MyTextInput
          label={page === 'withdraw' ? "Montant à retirer" : "Investissement souhaité"}
          value={amount}
          name="amount"
          type="number"
          placeholder="100 €"
          onChange={e => setAmount(e.target.value)}
          className="deposit-amount-width"
        />
        {showError && renderError()}
      </Form>
    </Formik>
  );
};

export default SetAmount;
