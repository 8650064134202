import React from 'react';

import './style.scss';
import OSButton from "../Button";
import DropdownButton from "../Button/Dropdown";
import {useNavigate} from "react-router-dom";


/**
 * A simple content component.
 *
 * @param {string} illustration - The illustation to display inside.
 * @param {string} text - The text to display
 * @param cta - Whether to add a CTA inside
 */

const EmptyContent = ({illustration, text, cta, dropdown}) => {
  const navigate = useNavigate();
  return (
    <div className="empty-content-container">
      {illustration}
      <p className="os-body2" style={{textAlign: "center"}}>{text}</p>
      <div className="empty-content-btn">
        {cta && <OSButton link={cta.link} title={cta.title} variant={cta.variant} size={cta.size} onClick={cta.onClick}/>}
        {dropdown && <DropdownButton
          title={dropdown.title}
          variant={dropdown.variant}
          size={dropdown.size}
          items={dropdown.items.map(product => ({
            label: product.name,
            onClick: () => navigate(`${window.location.pathname}/investissement/${product.category_slug}/${product.slug}/finalise`, {state: dropdown.urlState})
          }))}
        />}
      </div>
    </div>
  );
};

export default EmptyContent;
