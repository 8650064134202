import React from 'react';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as PrimaryLogo} from "./logoGreen.svg";
import {ReactComponent as MobileLogo} from "./os_mobile_logo.svg";

import OSButton from "../Button";
import DropdownButton from "../Button/Dropdown";

import './style.scss';

const Navbar = () => {
	const navigate = useNavigate();
  return (
    <div className="os-flex nav">
      <>
        <PrimaryLogo className="os-logo" onClick={() => window.location.replace('/')}/>
        <MobileLogo className="os-logo-phone" onClick={() => window.location.replace('/')}/>
      </>
      <div className="nav-items">
	      <DropdownButton
		      title="Je me connecte"
		      variant="primary-full"
		      size="medium"
		      items={[
			      {label: "Espace particulier", onClick: () => navigate('/sign-in')},
			      {label: "Espace partenaire", onClick: () => navigate('/sign-in/cgp')},
		      ]}
		      fit
	      />
       <OSButton title="Je m'inscris" variant="primary" fit link="/" size="medium" />
      </div>
    </div>
  );
};

export default Navbar;
