import {useEffect} from 'react';
import './style.scss';

const UnloggedRoutes = ({children}) => {
  useEffect(() => {
    if (window.location.href.includes('/cgp')) {
      localStorage.removeItem('UserAuthToken');
      localStorage.removeItem('user');
      localStorage.removeItem('defaultProfile');
    }
  }, []);

  return children
};

export default UnloggedRoutes;
