import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";

import {ReactComponent as LogoGreen} from '../../components/Navbar/logoGreen.svg';
import Phone from "../../components/Phone";
import PublicNav from "../../components/Navbar/publicNav";
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import Info from "../../components/info";
import images from "../../assets/images";

import OSButton from "../../components/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import './style.scss';

const Login = () => {
	const location = useLocation();
  const navigate = useNavigate();
  const authToken = localStorage.getItem('UserAuthToken');
	const [redirectUrl, setRedirectUrl] = useState('/overview');

  useEffect(() => {
    authToken && navigate('/sign-up/verify')
  }, [authToken]);
	
	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const url = searchParams.get('redirectUrl');
		
		url && setRedirectUrl(url);
	}, [location, navigate]);

  return (
    <>
      <Helmet>
        <title>Connexion</title>
      </Helmet>
      <PublicNav />
        <div className="container">
              <div className="image-section"/>
              <div className="auth-container">
                <LogoGreen className="auth-os-logo" />
                <div className="auth-phone-container">
                  <div className="os-flex__column top-container">
                    <h4 className="auth-title os-h4">Connectez-vous sur Openstone</h4>
                    <div className="os-flex top-form">
                      <span className="signup-text">Nouvel utilisateur ?
                        <a href="/sign-up" className="signup-link">Inscrivez-vous !</a>
                      </span>
                      <OSButton variant="primary-full" size="large" title="Vous êtes partenaire ?" fit link="/cgp" icon={<ArrowForwardIcon />} position="end"/>
                    </div>
	                  <Info logo={<LightbulbIcon/>} children="Titulaire d'un compte Livret P. ? Connectez-vous avec votre numéro de téléphone associé."/>
                  </div>
                  <Phone user={null} page="auth" redirectUrl={redirectUrl}/>
                </div>
                <div className="labels">
                  <img src={images.brand.labels} alt="Labels Openstone" />
                </div>
              </div>
        </div>
    </>
  );
};

export default Login;
