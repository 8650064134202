import React, {useState} from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextField from '@material-ui/core/TextField';
import {toast} from "react-toastify";

import authenticationStore from "../../../stores/AuthenticationStore.ts";

import './style.scss';
const CgpSignUpForm = ({setPhone}) => {
  const [loading, setLoading] = useState(false);
  const validationSchema = yup.object({
    companyName: yup.string().required('Requis'),
    legalFunction: yup.string().required('Requis'),
    siren: yup.string().min(9, "Minimum 9 charactères").max(9, "Maximum 9 charactères"),
    firstName: yup.string().required('Requis'),
    lastName: yup.string().required('Requis'),
    email: yup.string().email('Format invalide').required('Requis'),
    oriasId: yup.string().required('Requis').min(8, "Minimum 8 charactères").max(8, "Maximum 8 charactères"),
  });
  const formik = useFormik({
    initialValues: {
      companyName: '',
      legalFunction: '',
      siren: '',
      firstName: '',
      lastName: '',
      email: '',
      oriasId: '',
      difficulty: '',
      loss: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const response = await authenticationStore.cgpSignUp(values.email, values.firstName, values.lastName, values.companyName, values.siren.toString(), values.legalFunction, values.oriasId, values.loss, values.difficulty);
      if (response.cgp_user) {
        setLoading(false);
        toast.success('Votre inscription a bien été prise en compte !', {
          toastId: 'signUpSuccessToast'
        });
        setPhone(true)
      } else if (response.error) {
        toast.error(response.error,{toastId: 'signUpErrorToast'});
      }
      setLoading(false);
    },
  });

  return (
    <>
      <form className="user-signup-form" onSubmit={formik.handleSubmit}>
        <div className="user-signup-container">
          <TextField
            fullWidth
            id="companyName"
            name="companyName"
            label="Nom de votre société *"
            value={formik.values.companyName}
            onChange={formik.handleChange}
            error={formik.touched.companyName && Boolean(formik.errors.companyName)}
            helperText={formik.touched.companyName && formik.errors.companyName}
            variant={"outlined"}
          />
          <TextField
            fullWidth
            type="number"
            id="siren"
            name="siren"
            label="SIREN de votre société *"
            value={formik.values.siren}
            onChange={formik.handleChange}
            error={formik.touched.siren && Boolean(formik.errors.siren)}
            helperText={formik.touched.siren && formik.errors.siren}
            variant={"outlined"}
          />
          <div className="user-signup-container-names">
            <TextField
              fullWidth
              id="firstName"
              name="firstName"
              label="Prénom *"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
              variant={"outlined"}
            />
            <TextField
              fullWidth
              id="lastName"
              name="lastName"
              label="Nom de famille *"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              variant={"outlined"}
            />
          </div>
          <TextField
            fullWidth
            id="legalFunction"
            name="legalFunction"
            label="Votre fonction au sein de la société *"
            value={formik.values.legalFunction}
            onChange={formik.handleChange}
            error={formik.touched.legalFunction && Boolean(formik.errors.legalFunction)}
            helperText={formik.touched.legalFunction && formik.errors.legalFunction}
            variant={"outlined"}
          />
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Adresse e-mail *"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            variant={"outlined"}
          />
          <TextField
            fullWidth
            id="oriasId"
            name="oriasId"
            label="Numéro d'enregistrement ORIAS (CIF) *"
            value={formik.values.oriasId}
            onChange={formik.handleChange}
            error={formik.touched.oriasId && Boolean(formik.errors.oriasId)}
            helperText={formik.touched.oriasId && formik.errors.oriasId}
            variant={"outlined"}
          />
          <div className="os-flex gap-16">
            <TextField
              type="checkbox"
              id="loss"
              defaultChecked={formik.values.difficulty}
              name="difficulty"
              value={formik.values.difficulty}
              onChange={formik.handleChange}
              error={formik.touched.difficulty && Boolean(formik.errors.difficulty)}
            />
            <label htmlFor="loss" className="os-caption">J'ai conscience du fait que mon client aura des difficultés à revendre ses titres avant l’échéance</label>
          </div>
          <div className="os-flex gap-16">
            <TextField
              type="checkbox"
              id="loss"
              defaultChecked={formik.values.loss}
              name="loss"
              value={formik.values.loss}
              onChange={formik.handleChange}
              error={formik.touched.loss && Boolean(formik.errors.loss)}
            />
            <label htmlFor="loss" className="os-caption">J'ai conscience du fait que mon client peut perdre éventuellement la totalité de son investissement</label>
          </div>
        </div>

        <div className="user-signup-btn-container">
          <button type="submit" className="user-signup-btn" disabled={loading}>Continuer</button>
        </div>
      </form>
    </>
  );
};

export default CgpSignUpForm;
