import React, {useState} from 'react';
import StepsContainer from "../../StepsContainer";
import CustomTab from "../../../../../../components/CustomTab";
import Images from "../../../../../../assets/images";
import CgpNetwork from "../../../../../../config/Network/CgpNetwork.ts";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import TextField from "@material-ui/core/TextField";
import OSButton from "../../../../../../components/Button";
import * as yup from "yup";
import {useSearchParams} from "react-router-dom";
import MoneyHelper from "../../../../../../Helpers/MoneyHelper";

const CgpPaiementChoice = ({object, product, clientId, clientType, nextStep, updateObject}) => {
  const [searchParams, setSearchParams] = useSearchParams();
	const [amount, setAmount] = useState(localStorage.getItem('InvestmentAmount') || null);
  const [activeTab, setActiveTab] = useState(1);
  const [submitting, setSubmitting] = useState(null);
  const [ibanValid, setIbanValid] = useState(object.iban ? true : false);
  const tabs = [
    {id: 1, title: "Virement SEPA", description: "Un email avec les informations de virement sera envoyé au client", img: <img src={Images.paiementOption.sepa} alt="" />, clickable: true},
    //{id: 2, title: "Mandat de prélèvement SEPA", description: "Paiement par mandat de prélèvement SEPA", img: <img src={Images.paiementOption.bank} alt="" />, clickable: true},
  ];

  function handleTabClick(tabId) {
    setActiveTab(tabId);
  }

  const validationSchema = yup.object({
    iban: yup.string().min(27, "L'IBAN doit être composé de 27 charactères").max(27, "L'IBAN doit être composé de 27 charactères").matches(/^FR/, "L'IBAN doit être français 'FR76...'")
  })

  const formik= useFormik({
    initialValues: {
      iban: object.iban || '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSubmitting(true);
      const updateClient = async () => {
        clientType === 'Company' ? await CgpNetwork.updateRecord({iban: values.iban}, clientId, 'Company') : await CgpNetwork.updateRecord({ iban: values.iban}, clientId, 'User');
      }
      const response = updateClient();
      if (response) {
        updateObject(true);
        setIbanValid(true);
        setSubmitting(false);
        toast.success('Vos informations personnelles ont bien été enregistrés', {
          toastId: 'completeInfosSuccessToast'
        });
      } else {
        toast.error("Une erreur est survenue lors de l'enregistrement de vos informations",{
          toastId: 'completeInfosErrorToast'
        });
      }
    },
  });

  const ibanTab = (
    <>
      {ibanValid ? (
        <>
          <h6 className="os-h6">Veuillez vérifier l'IBAN du client - {clientType === 'Company' ? object.legal_name : object.full_name}</h6>
          <div className="os-flex gap-16" style={{alignItems: "center"}}>
            <p>{object.iban}</p>
            <OSButton variant="primary-full" size="medium" onClick={() => setIbanValid(false)} title="Éditer" fit />
          </div>
        </>
      ) : (
        <form className="os-user-survey-form" onSubmit={formik.handleSubmit}>
          <>
            <h6 className="os-h6">Informations bancaires</h6>
            <p>Renseignez un IBAN français afin de pouvoir utiliser le prélèvement SEPA lors de la souscription</p>
            <p>Conseil: Noter manuellement les IBAN afin d'éviter toute erreur / usurpation</p>
            <div className="os-flex gap-32">
              <TextField
                fullWidth
                id="iban"
                name="iban"
                label="IBAN"
                value={formik.values.iban}
                onChange={formik.handleChange}
                error={formik.touched.iban && Boolean(formik.errors.iban)}
                helperText={formik.touched.iban && formik.errors.iban}
                variant={"outlined"}
              />
              <div className="user-survey-btn-container">
                <button type="submit" className="user-survey-btn" disabled={submitting}>Enregistrer</button>
              </div>
            </div>
          </>
        </form>
      )}
      {ibanValid && (
        <div className="paiement-btn-container">
          <button className="choose-payment-button__button" onClick={() => {
            localStorage.setItem('InvestmentType', 'sepaMandate')
            setSearchParams({transaction_type: 'sepaMandate'})
            nextStep(true);
          }}>
            Continuer
          </button>
        </div>
      )}
    </>
  )

  const renderCurrentTab = () => {
    if (activeTab === 1) {
      return (
        <div className="paiement-btn-container">
          <button className="choose-payment-button__button" onClick={() => {
            localStorage.setItem('InvestmentType', 'wireTransfer')
            setSearchParams({transaction_type: 'wireTransfer'})
            nextStep(true);
          }}>
            Continuer
          </button>
        </div>
      )
    } else if (activeTab === 2) {
      return ibanTab;
    }
  }

  return (
    <>
      <StepsContainer product={product}
                      children={
        <>
          <p className="os-subtitle1">Veuillez choisir la solution de versement souhaitée pour le client</p>
          <div className="os-flex gap-32">
            {tabs.map(tab => <CustomTab key={tab.id} tab={tab} clickable={tab.clickable} onTabClick={handleTabClick}
                                        activetab={activeTab} selected={activeTab === tab.id}
                                        disabled={tab?.disabled}/>)}
          </div>
          {(activeTab !== null) && renderCurrentTab()}
        </>}
      />
    </>
  );
};

export default CgpPaiementChoice;
