// components
import Chart, { useChart } from '../chart';
import { styled } from '@mui/material/styles';
import MoneyHelper from "../../../Helpers/MoneyHelper";


// ----------------------------------------------------------------------
const CHART_HEIGHT = 350;

const LEGEND_HEIGHT = 90;

const StyledChart = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': {
    height: CHART_HEIGHT,
  },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
    minWidth: '350px',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    width: '110%',
    marginLeft: -17,
    alignContent: 'center',
    borderTop: `solid 1px rgba(145, 158, 171, 0.24)`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
  '& .apexcharts-text': {
    fontSize: '1.2rem !important',
  }
}));

export default function ChartDonut({data, type}) {
  const series = data;
  const chartOptions = useChart({
    stroke: {
      show: true,
    },
    legend: { floating: true, horizontalAlign: 'bottom' },
    plotOptions: {
      pie: {
        donut: {
          size: '88%',
          labels: {
            value: {
              formatter: (value) => MoneyHelper.formatEuro(value),
            },
            total: {
              formatter: (w) => {
                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                return MoneyHelper.formatEuro(sum);
              },
            }
          },
        },
      },
    },
    fill: {
      colors: ['#264C51', '#006F8E', '#DD9104', '#36B37E']
    },
    colors: ['#264C51', '#006F8E', '#DD9104', '#36B37E'],
    labels: type !== 'cgp' ? ['Livret P', 'Club Deals', 'Private Equity'] : ['Club Deals', 'Private Equity'],
  });

  return (
    <StyledChart dir="ltr">
      <Chart type="donut" series={series} options={chartOptions} width="100%" height={230}/>
    </StyledChart>
  )
}
