import React, {useState} from "react";
// @mui
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from '@mui/material';
//import Menu from '@mui/material/Menu';
//import MenuItem from '@mui/material/MenuItem';
//import IconButton from '@mui/material/IconButton';
//import MoreVertIcon from '@mui/icons-material/MoreVert';
//import VisibilityIcon from '@mui/icons-material/Visibility';

import Scrollbar from '../../scrollbar';
import { TableHeadCustom } from '../index.js';
import MoneyHelper from "../../../Helpers/MoneyHelper";

import {useNavigate} from "react-router-dom";
import getBadge from "../../Badge/getBadge";

import '../myTable.scss';
import OSButton from "../../Button";

// ----------------------------------------------------------------------
export default function ClientsTable({ title, subheader, tableData, tableLabels, ...other }) {
  return (
    <Card {...other}>
      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar>
          <Table sx={{ minWidth: 720 }}>
            <TableHeadCustom headLabel={tableLabels} />

            <TableBody>
              {tableData.map((row, index) => <ClientsTableRow key={`tableCell${index}-${row.record.created_at}`} row={row} /> )}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
    </Card>
  );
}

// ----------------------------------------------------------------------

function ClientsTableRow({ row }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const record = row.record;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  // Add your action handlers here
  const seeAction = () => {
    if (row.listable_type === 'User') {
      localStorage.setItem('User', JSON.stringify(row.record.id))
    } else {
      localStorage.setItem('Company', JSON.stringify(row.record.id))
    }
    navigate(`${window.location.pathname}/${row.record.id}`, {state: {clientId: row.record.id, listableType: row.listable_type}})
    handleClose();
  };

  const editAction = () => {
    if (row.listable_type === 'User') {
      navigate(`${window.location.pathname}/${row.record.id}/personne-physique/edition`, {state: {clientId: row.record.id}})
      localStorage.setItem('User', JSON.stringify(row.record.id))
    } else {
      navigate(`${window.location.pathname}/${row.record.id}/personne-morale/edition`, {state: {clientId: row.record.id}})
      localStorage.setItem('Company', JSON.stringify(row.record.id))
    }
    handleClose();
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <div>
            {record.name}
          </div>
        </TableCell>
        <TableCell>
          <div>
            {MoneyHelper.formatEuro(record.fund_shares.total)}
          </div>
        </TableCell>
        <TableCell>
          <div>{row.listable_type === 'Company' ? 'Personne morale' : 'Personne physique'}</div>
        </TableCell>
        <TableCell>
          {getBadge(record.kyc_status, 'kyc_status')}
        </TableCell>
        <TableCell>
          <div className="os-flex gap-16 table-btns">
            { record.kyc_status !== "verified" && <OSButton onClick={() => editAction()} variant="no_border-info" fit size="" title="Éditer"/>}
            <OSButton onClick={() => seeAction()} variant="no_border-info" fit size="" title="Accéder" />
          </div>
          {/*
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: '20ch',
              },
            }}
          >
            <MenuItem onClick={handleAction1}>
              Consulter
            </MenuItem>
            <MenuItem onClick={handleAction2}>
              Ajouter un investissement
            </MenuItem>
          </Menu>
          */}
        </TableCell>
      </TableRow>
    </>
  );
}
