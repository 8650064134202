import React from 'react';
import {useNavigate} from "react-router-dom";

import StepsContainer from "../StepsContainer";
import EmptyContent from "../../../../../components/empty-content/EmptyContent";

import {ReactComponent as NoDocs} from "../../../../../assets/Illustrations/parrainage.svg";

const SentSuccess = ({product, clientId, clientType}) => {
  const navigate = useNavigate();

  return (
    <div>
      <StepsContainer children={
        <>
          <EmptyContent text="Les documents de souscriptions ont été envoyés avec succès au client pour signature éléctronique."
                        cta={{title: "Voir l'investissement", variant: "primary", size: "large", onClick: () => navigate(`/cgp/clients/${clientId}/investissement/${product.category_slug}/${product.category_slug}`, {state: {id: product.id, clientId: clientId, clientType: clientType}})}}
                        illustration={<NoDocs />}
          />
        </>
      } product={product} />
    </div>
  );
};

export default SentSuccess;
