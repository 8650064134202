import React from 'react';
import {Link} from "react-router-dom";

import {ReactComponent as ChevronRight} from "../../pages/Opportunities/View/icons/ic_chevron_right.svg";

import './style.scss';

const AccountCardSelection = ({tittle, img,link}) => (
    <Link to={link} className="card-selection">
        <div className="card-selection-section">
            <img className="card-selection-icon" src={img} alt="icon placeholder" />
            <span className="card-selection-title ">{tittle}</span>
            <Link to={link} ><ChevronRight/> </Link>
        </div>
    </Link>
);

export default AccountCardSelection;
