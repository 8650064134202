import React from 'react';
import {useLocation} from "react-router";
import {Helmet} from "react-helmet";

import ChooseBank from "../../chooseBank";

const BankPayment = () => {
  const {state} = useLocation();
  const { amount, page, product, transactionId } = state;

  return (
    <>
      <Helmet>
        <title>Choix de la banque</title>
      </Helmet>
      <div className="banks-container">
        <ChooseBank amount={amount} page={page} product={product} transactionId={transactionId} />
      </div>
    </>
  );
};

export default BankPayment;
