export default {
  brand: {
    paris: require('./brand/paris.webp'),
    livretpwhitelogo: require('./brand/livretpwhitelogo.png'),
    team: require('./brand/team.png'),
    labels: require('./brand/labels.png'),
	  squareLogo: require('./brand/logo/os-white-square.png'),
  },
  paiementOption: {
    bank: require('./PaiementOptions/bank.png'),
    card: require('./PaiementOptions/card.png'),
    sepa: require('./PaiementOptions/sepa.png'),
    livretp: require('./PaiementOptions/livretp.png'),
  },
  charlotte: require('./charlotte.png'),
  parrainage: require('./parrainage.png'),
  helping: require('./helping.png'),
  primaryBackground: require('./primaryBackground.jpg'),
  up: require('./up.png'),
  down: require('./down.png'),
  review: require('./review.png'),
  yoan: require('./yoan.png'),
  charlotteAvatar: require('./charlotte_avatar.png'),
  charles: require('./charles.jpg'),
  sebastien: require('./sebastien.jpg'),
  funds : require('./fundRepartition.png'),
  secured: require('./secure.png'),
  coupon: require('./coupon.png'),
  partner:require('./partners.png'),
  particulier: require('./avatar (1).png'),
  referral: {
    referralBackground: require('./Referral/referral.png')
  },
  boost: {
    boostBackground: require('./Boost/boost.png')
  },
  cgp: {
    speed: require('./Cgp/speed.png'),
    independant: require('./Cgp/hold.png'),
    accesibility: require('./Cgp/liquidity.png'),
    growth: require('./Cgp/growth.png'),
    performance: require('./Cgp/performance.png'),
    ressources: {
      first: require('./Cgp/Ressources/first_ressources.png'),
      second: require('./Cgp/Ressources/second_ressources.png')
    }
  },
  collaboration: {
    horiz: require('./collaborations/horiz/osxhoriz_primary.png'),
    hold: require('./collaborations/horiz/hold.png'),
    balance: require('./collaborations/horiz/balance.png'),
    liquidity: require('./collaborations/horiz/liquidity.png'),
    occupancy: require('./collaborations/horiz/occupancy.png'),
    target: require('./collaborations/horiz/target.png'),
    growth: require('./collaborations/horiz/growth.png'),
  }
};
