import React, {useEffect, useState} from 'react';

import BridgeNetwork from "../../../../config/Network/BridgeNetwork.ts";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';


import BankLoader from "../../../../components/Loaders/Bank";
import {toast} from "react-toastify";

import './style.scss';
const ChooseBank = ({amount, page, product, transactionId}) => {
  const defaultProfile = useState(JSON.parse(localStorage.getItem('defaultProfile')))[0];
  const [loading, setLoading] = useState(true);
  const [banks, setBanks] = useState([]);
  const [showChildren, setShowChildren] = useState(false);
  const [bankChildren, setBankChildren] = useState([])

  const initialiseTransaction = async bank => {
    const response = await BridgeNetwork.initialiseTransaction(
      bank.id,
      amount,
      defaultProfile ? 'user' : 'company',
			transactionId,
    );

    if (response) {
      return response;
    }
    return false;
  };

  const initialiseProductTransaction = async bank => {
    const response = await BridgeNetwork.initialiseProductTransaction(
      bank.id,
      amount,
      product.id,
      product.category_id,
      transactionId,
      defaultProfile ? 'user' : 'company'
    );

    if (response) {
      return response;
    }
    return false;
  };

  const connectToBank = async bank => {
    setLoading(true);
    let response;
    page !== 'livretp'
      ? response = await initialiseProductTransaction(bank)
      : response = await initialiseTransaction(bank)

    if (response) {
      window.location.replace(response.data.url + `/bank/${bank.id}`);
    } else {
      toast.error('Une erreur est survenue pendant la connection avec la banque', {
        toastId: 'bankErrorToast'
      });
    }
    setLoading(false);
  };

  function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  const BankCard = ({bank}) => {
    return (
      <div className="bank-card" onClick={() => {
        if (bank?.children?.length > 1) {
          setBankChildren(bank?.children)
          setShowChildren(true)
        } else {
          connectToBank(bank?.children ? bank?.children[0] : bank);
        }
      }}>
        <img src={bank.logo_url} alt={bank.name} className="bank-card__img"/>
        <p className="bank-card__name">{bank.name.length > 20 ? bank.name.substring(0,20).concat('...') : bank.name}</p>
      </div>
    )
  }

  const loadBanks = async () => {
    const response = await BridgeNetwork.list();
    if (response) {
      setBanks(groupBanks(response.list));
      setTimeout(() => {
        setLoading(false);
      }, 500)
    }
  }

  const groupBanks = list => {
    let parentGroups = {};

    list.forEach(item => {
      if (item.parent_name) {
        if (!parentGroups[item.parent_name]) {
          parentGroups[item.parent_name] = {
            name: item.parent_name,
            logo_url: '',
            children: [],
          };
        }

        if (!parentGroups[item.parent_name].logo_url && item.logo_url) {
          parentGroups[item.parent_name].logo_url = item.logo_url;
        }

        parentGroups[item.parent_name].children.push(item);
      } else {
        parentGroups[item.name] = {
          ...item,
          children: [item],
        };
      }
    });

    return Object.values(parentGroups);
  };

  const renderBanks = () => {
    let component;
    if (loading || banks.length === 0) {
      component = Array.from({ length: 18 }, (x, i) => {
        return <BankLoader />;
      });
    } else if (!loading && banks.length > 0) {
      component = banks.map(b => <BankCard bank={b} key={`${b.name +b.id}`}/>);
    }
    return component;
  }

  const showChildrenBanks = () => {
    return (
      <>
        <div className="bank-card" onClick={() => {
          setShowChildren(false);
          setBankChildren([]);
        }}>
          <ArrowBackIcon />
        </div>
        {bankChildren.map(b => <BankCard bank={b} key={`${b.name +b.id}`}/>)}
      </>
    );
  }

  useEffect(() => {
    loadBanks()
  }, [])

  return (
    <div className="choose-container">
      <h3 className="os-h3">Choisissez votre banque :</h3>
      <div className="banks">
        {showChildren ? showChildrenBanks() : renderBanks()}
      </div>
    </div>
  );
};

export default ChooseBank;
