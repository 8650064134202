import React, {useRef, useState} from 'react';
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useSearchParams} from "react-router-dom";

import CartNetwork from "../../config/Network/CartNetwork.ts";

import WireTransfer from "../../pages/livretp/Deposit/Payment/WireTransfer";
import CustomTab from "../CustomTab";
import OSButton from "../Button";
import MoneyHelper from "../../Helpers/MoneyHelper";

import Images from "../../assets/images";

import './style.scss';

const ChoosePaymentType = ({page, product, user, object}) => {
  const defaultProfile = useState(JSON.parse(localStorage.getItem('defaultProfile')))[0];
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const currentPageLivretP = (page === 'livretp' || page === 'Livret P.');
  const [activeTab, setActiveTab] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(parseInt(localStorage.getItem('InvestmentAmount')) || 0);
  const transactionId = searchParams.get('transaction_id');
  const wireRef = useRef(null);
  const lpBalanceVsAmount =  object.euro_balance >= amount;

  const lpText = (
    <p>Déposer avec votre livret P.
      <p>Solde actuel:
        <span className={`${lpBalanceVsAmount ? 'success' : 'error'}`}> {MoneyHelper.formatEuro(object?.euro_balance)}</span>
      </p>
      {!lpBalanceVsAmount && (
        <p>Votre solde est inférieur au montant que vous souhaitez déposer</p>
      )}
    </p>
  );

  const cardText = (
      <p>Paiement par carte bancaire (VISA, Mastercard)
          {amount > 10000 &&
              <span className="error">Les paiements par carte ne sont pas autorisés au dessus de 10 000€</span>}
      </p>
  );

  const tabs = [
    {id: 1, title: "Livret P.", description: lpText, img: <img src={Images.paiementOption.livretp} alt="" />, clickable: !currentPageLivretP, disabled: !lpBalanceVsAmount},
    {id: 2, title: "Compte bancaire", description: "Paiement par compte bancaire avec BRIDGE API, agréé par la Banque de France", img: <img src={Images.paiementOption.bank} alt="" />, clickable: true},
    {id: 3, title: "Carte bancaire", description: cardText, img: <img src={Images.paiementOption.card} alt="" />, clickable: amount < 10001, disabled: amount > 10000},
    {id: 4, title: "Virement SEPA", description: "Virement SEPA standard", img: <img src={Images.paiementOption.sepa} alt="" />, clickable: true},
  ];
  function checkCategory(response) {
    if (currentPageLivretP) {
      navigate(`/opportunites/livret-p/deposit?transaction_id=${transactionId}&paiementType=card`, {state: {amount: amount.toString(), page: page, token: response.data.answer.formToken.toString(), transactionId: transactionId}});
    } else {
      navigate(`../${window.location.pathname}${window.location.search}&paiementType=card`, {state: {amount: amount.toString(), page: page, token: response.data.answer.formToken.toString(), transactionId: transactionId}, replace: true});
    }
  }
	
  const initialiseFormToken = async () => {
    setIsLoading(true);
    const response = await CartNetwork.initialiseFormToken(amount, page, product && product.id, parseInt(transactionId), defaultProfile ? 'user' : 'company');
    if (response) {
      checkCategory(response);
      setIsLoading(false);
    } else {
      toast.error("La connection à notre prestataire de paiement par carte, n'a pas aboutie, veuillez re-essayer", {
        toastId: "cardConnectionErrorToast"
      })
      setIsLoading(false);
    }
  }

  const payByAccount = (data) => {
    setIsLoading(true);
    navigate('bank-paiement', { state: { amount: (currentPageLivretP) ? amount.toString() : data.toString(), page: page, product: product, transactionId: transactionId}})
  }

  const payByTransfer = (data) => {
    setIsLoading(true);
    navigate(`../${window.location.pathname}${window.location.search}&paiementType=livretp`, { state: { amount: data.toString(), page: page, product: product, transactionId: transactionId}})
  }

  function handleTabClick(tabId) {
    setActiveTab(tabId);
  }

  const renderTab = () => {
    let element; let tabTitles;
    if (currentPageLivretP) {
      tabTitles = tabs.slice(-3).map(tab => <CustomTab key={tab.id} tab={tab} clickable={tab.clickable} onTabClick={handleTabClick} selected={activeTab === tab.id} disabled={tab?.disabled}/>)
    } else {
      if (product.category_id === 1 ) {
        element = <WireTransfer page={page} product={product} amount={amount} transactionId={transactionId} user={user}/>
        tabTitles = null;
      } else {
        tabTitles = tabs.map(tab => <CustomTab key={tab.id} tab={tab} clickable={tab.clickable} onTabClick={handleTabClick} activetab={activeTab} selected={activeTab === tab.id} disabled={tab?.disabled}/>)
      }
    }
    activeTab === 4 && (element = <WireTransfer page={page} product={product} amount={amount} transactionId={transactionId} user={user}/>);
    (activeTab === 1 && !lpBalanceVsAmount) && (element = <></>)
    return {element, tabTitles};
  }

  const checkout = () => {
    if (activeTab === 1) {
      payByTransfer(amount);
    } else if (activeTab === 2) {
      payByAccount(amount);
    } else if (activeTab === 3) {
      initialiseFormToken(amount);
    }
  };
	
	const checkTab = () => {
		 if (activeTab !== null && activeTab !== 4 && activeTab !== 3){
			 return (
				<div className="os-flex gap-16 paiement-btn-container">
					<OSButton variant="primary" size="large" fit onClick={() => checkout()} title="Continuer" />
				</div>
			 )
		} else if (activeTab !== null && activeTab === 3) {
			 return (
				 <div className="os-flex gap-16 paiement-btn-container">
					 {amount < 10001 ? (
						 <>
							 {activeTab === 3 && <span className="warning">Des frais de 0.5% s'appliquent lorsque le montant est supérieur à 2000€</span>}
							 <OSButton variant="primary" size="large" fit onClick={() => checkout()} title="Continuer" />
						 </>
					 ) : (
						 <span className="error">Les paiements par carte ne sont pas autorisés au dessus de 10 000€</span>
					 )}
				 </div>
			 )
		 } else if (activeTab !== null && activeTab === 4) {
			 return renderTab().element
		 }
	}

  return (
    <>
      <h4 className="os-h4">Mode de paiement</h4>
      <div className="amount-recap-container">
        <h5 className="os-h5">Montant de votre investissement: {MoneyHelper.formatEuro(amount)}< /h5>
      </div>
      <div className="os-flex gap-16 paiement-choices">
        {renderTab().tabTitles}
      </div>
      {checkTab()}
    </>
  );
};

export default ChoosePaymentType;
