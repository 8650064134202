import React from 'react';

import OsCard from "../../../components/card/basic";
import Images from "../../../assets/images";

import './style.scss';

const Performance = () => {
  return (
    <div className="os-flex__column performance-container">
      <OsCard bg="green">
        <div className="os-flex announcement_container">
          <div className="announcement-left">
            <h4 className="os-h4" style={{color: 'white'}}>À venir prochainement</h4>
            <p className="os-body1">Suivez les performances de vos souscriptions</p>
            <p className="os-body1" style={{ color: 'white'}}>Sur cet espace, vous accéderez à un certain nombre de données relatives à vos clients, les investissements et leurs performances, ainsi que vos commissions reversées.</p>
          </div>
          <img src={Images.cgp.performance} alt="" className="performance-container__img"/>
        </div>
      </OsCard>
    </div>
  );
};

export default Performance;
