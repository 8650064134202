import React from 'react';
import {Routes, Route} from 'react-router-dom';

import ProtectedRoute from "./protectedRoutes";
import Auth from "../../pages/auth";
import Verify from "../../pages/auth/Verify";
import Dashboard from "../../pages/Dashboard";
import LivretP from "../../pages/livretp";
import Deposit from "../../pages/livretp/Deposit";
import BankPayment from "../../pages/livretp/Deposit/Payment/BankPayment";
import Callback from "../../pages/livretp/Deposit/Payment/BankPayment/callback";
import Profile from "../../pages/Profile";
import KYC from "../../pages/KYC";
import Withdraw from "../../pages/livretp/Withdraw";
import Boosts from "../../pages/Boosts";
import Survey from "../../pages/livretp/Withdraw/Survey";
import Referrals from "../../pages/referrals";
import More from "../../pages/More";
import Signup from "../../pages/auth/signup";
import NotFound from "../../pages/ErrorPages/NotFound";
import BoostQuestion from "../../pages/Boosts/Question";
import Opportunities from "../../pages/Opportunities";
import OpportunityView from "../../pages/Opportunities/View";
import Finalise from "../../pages/Opportunities/Finalise";
import UserSurvey from "../../pages/KycSurvey";
import MyDocuments from "../../pages/Profile/MyDocuments";
import PartnersView from "../../pages/Partners";
import CategoryInvestments from "../../pages/Opportunities/Investment/category";
import CompanyForm from "../../pages/Company/Survey";
import ProductInvestment from "../../components/Product/Investment";
import Documents from "../../pages/Profile/MyDocuments/Documents";
import BsTransactions from "../../pages/Profile/MyDocuments/BsTransactions";
import ProductTransactions from "../../pages/Transactions";
import Promotions from "../../pages/promotions";
import BrTransactions from "../../pages/Profile/MyDocuments/BrTransactions";
import CompaniesList from "../../pages/Company/List";
import CgpDashboard from "../../pages/Cgp/Dashboard";
import CgpSignUp from "../../pages/auth/signup/cgp";
import CgpRoutes from "./cgpRoutes";
import ClientsGestion from "../../pages/Cgp/Gestion";
import ClientView from "../../pages/Cgp/Gestion/View";
import CgpLogin from "../../pages/auth/cgp_login";
import Performance from "../../pages/Cgp/Performance";
import Campaigns from "../../pages/Cgp/Performance/campaigns";
import CgpVerification from "../../pages/Cgp/Verification";
import UnloggedRoutes from "./unloggedRoutes";
import CgpGuides from "../../pages/Cgp/Guides";
import CgpSignUpUploads from "../../pages/Cgp/Verification/cgpUploads";
import Horizio from "../../pages/collaborations/horizio";
import AccountSelection from "../../pages/auth/Selection/Selection.js"
import CGPProfile from "../../pages/Cgp/Profile";

const handleProtect = component => (
  <ProtectedRoute>{component}</ProtectedRoute>
)

const handleCgp = component => (
  <CgpRoutes>{component}</CgpRoutes>
)

const handleLogout = component => (
  <UnloggedRoutes>{component}</UnloggedRoutes>
)

function OSRoutes() {
  const cgpAuthToken = localStorage.getItem('CgpUserAuthToken');
  return (
    <Routes>
      {/* Public Routes*/}

      <Route path="/" element={handleLogout(<AccountSelection/>)}></Route>
      <Route path="/sign-in" element={handleLogout(<Auth />)} />
      <Route path="/sign-up" element={handleLogout(<Signup />)} />
      <Route path="/sign-up/verify" element={handleLogout(<Verify />)} />
      <Route path="/openstone-x-horiz" element={<Horizio />} />
	    <Route path="/opportunites/:category/:id/public" element={<OpportunityView restricted={false} />} />
        {/* Public Routes for cgp actions*/}
      <Route path="/cgp" element={handleLogout(<CgpSignUp />)} />
      <Route path="/sign-in/cgp" element={handleLogout(<CgpLogin />)} />
      <Route path="/sign-up/cgp" element={handleLogout(<CgpSignUp />)} />
      {/* Basic protected routes that requires a UserAuthToken for basic users*/}
      <Route path="/more" element={cgpAuthToken ? handleCgp(<Opportunities />) : handleProtect(<More />)} />
      <Route path="/overview" element={handleProtect(<Dashboard/>)} />
      <Route path="/profile" element={cgpAuthToken ? handleCgp(<CGPProfile />) : handleProtect(<Profile />)} />
      <Route path="/documents" element={handleProtect(<MyDocuments />)} />
      <Route path="/documents/generaux" element={handleProtect(<Documents />)} />
      <Route path="/documents/bulletins/souscriptions" element={handleProtect(<BsTransactions />)} />
      <Route path="/documents/bulletins/retraits" element={handleProtect(<BrTransactions />)} />
      <Route path="/kyc" element={handleProtect(<KYC />)} />
      <Route path="/company/create" element={handleProtect(<CompanyForm />)} />
      <Route path="/company/edit" element={handleProtect(<CompanyForm />)} />
      <Route path="/companies" element={handleProtect(<CompaniesList />)} />
      <Route path="/opportunites" element={cgpAuthToken ? handleCgp(<Opportunities />) : handleProtect(<Opportunities />)} />
      <Route path="/overview/:category/investments" element={handleProtect(<CategoryInvestments />)} />
      <Route path="/opportunites/:category" element={cgpAuthToken ? handleCgp(<Opportunities />) : handleProtect(<Opportunities />)} />
      <Route path="/opportunites/:category/:id" element={cgpAuthToken ? handleCgp(<OpportunityView restricted={true}/>) : handleProtect(<OpportunityView restricted={true}/>)} />
      <Route path="/opportunites/:category/:id/my-investment" element={handleProtect(<ProductInvestment />)} />
      <Route path="/opportunites/:category/:id/my-investment/transactions" element={handleProtect(<ProductTransactions />)} />
      <Route path="/opportunites/:category/:id/finalise" element={handleProtect(<Finalise />)} />
      <Route path="/opportunites/:category/:id/finalise/bank-paiement" element={handleProtect(<BankPayment/>)} />
      <Route path="/opportunites/:category/:id/finalise/user_survey" element={handleProtect(<UserSurvey />)} />
      <Route path="/opportunites/:category/:id/finalise/paiement-status" element={handleProtect(<Callback/>)} />
      <Route path="/opportunites/livret-p/my-investment" element={handleProtect(<LivretP />)} />
      <Route path="/opportunites/livret-p/transactions" element={handleProtect(<ProductTransactions />)} />
      <Route path="/opportunites/livret-p/deposit" element={handleProtect(<Deposit/>)} />
      <Route path="/opportunites/livret-p/deposit/bank-paiement" element={handleProtect(<BankPayment/>)} />
      <Route path="/opportunites/livret-p/deposit/bridge/callback" element={handleProtect(<Callback/>)} />
      <Route path="/opportunites/livret-p/withdraw" element={handleProtect(<Withdraw/>)}/>
      <Route path="/opportunites/livret-p/withdraw/survey" element={handleProtect(<Survey/>)}/>
      <Route path="/opportunites/livret-p/boosts" element={handleProtect(<Boosts />)} />
      <Route path="/opportunites/livret-p/boosts/question-de-la-semaine" element={handleProtect(<BoostQuestion />)}/>
      <Route path="/parrainage" element={handleProtect(<Referrals />)} />
      <Route path="/partners/:id" element={handleProtect(<PartnersView  />)} />
      <Route path="/user_survey" element={handleProtect(<UserSurvey />)} />
      <Route path="/promotions" element={handleProtect(<Promotions />)} />
      {/* CgpUsers protected routes that requires a CgpUserAuthToken for cgp users*/}
      <Route path="/sign-up/cgp/documents" element={<CgpSignUpUploads />} />
      <Route path="/cgp/verification" element={<CgpVerification />} />
      <Route path="/cgp/dashboard" element={handleCgp(<CgpDashboard />)} />
      <Route path="/cgp/guides" element={handleCgp(<CgpGuides />)} />
      <Route path="/cgp/performance" element={handleCgp(<Performance />)} />
      <Route path="/cgp/campagnes" element={handleCgp(<Campaigns />)} />
      <Route path="/cgp/clients" element={handleCgp( <ClientsGestion />)} />
      <Route path="/cgp/clients/:clientId" element={handleCgp( <ClientView />)} />
      <Route path="/cgp/clients/:clientId/documents" element={handleCgp( <Documents cgp={true} />)} />
      <Route path="/cgp/clients/creation/personne-morale" element={handleCgp(<CompanyForm />)} />
      <Route path="/cgp/clients/creation/personne-physique" element={handleCgp(<UserSurvey />)} />
      <Route path="/cgp/clients/:clientId/personne-physique/edition" element={handleCgp( <UserSurvey editing={true}/>)} />
      <Route path="/cgp/clients/:clientId/personne-morale/edition" element={handleCgp( <CompanyForm editing={true}/>)} />
      <Route path="/cgp/clients/:clientId/investissement/:category/:id/finalise" element={handleCgp(<Finalise cgp={true}/>)} />
      <Route path="/cgp/clients/:clientId/investissement/:category/:id/transactions" element={handleCgp(<ProductTransactions />)} />
      <Route path="/cgp/clients/:clientId/investissement/:category/:id" element={handleCgp( <ProductInvestment cgp={true}/>)} />
      <Route path="*" element={<NotFound cgp={!!cgpAuthToken}/>} />
    </Routes>
  );
}
export default OSRoutes;
