import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Link, useNavigate} from "react-router-dom";

import authenticationStore from "../../../stores/AuthenticationStore.ts";

import Images from "../../../assets/images";
import { ReactComponent as LogoGreen } from '../../../components/Navbar/logoGreen.svg';

import PublicNav from "../../../components/Navbar/publicNav";
import CgpSignUpForm from "../../../Forms/Auth/SignUp/cgp";
import Phone from "../../../components/Phone";
import images from "../../../assets/images";

import './style.scss';
import Footer from "../../../components/Footer";
import OSButton from "../../../components/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const CgpSignUp = () => {
  const navigate = useNavigate();
  const authToken = localStorage.getItem('CgpUserAuthToken');
  const [otp, setOtp] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const elements = [
    {img: Images.cgp.accesibility, content: <p className="os-body1 auth-img-text ">Un accès à des produits haut de gamme à  partir de <span className="important">10 000€</span> seulement</p>},
    {img: Images.cgp.growth, content: <p className="os-body1 auth-img-text "><span className="important">Une sélection très rigoureuse</span>.<br/>Notre équipe approuve moins de 10 % des deals qu'elle évalue avec des performances entre <span className="important">8% et 20%</span></p>},
    {img: Images.cgp.speed, content: <p className="os-body1 auth-img-text ">Une souscription <span className="important">100% en ligne</span></p>},
    {img: Images.cgp.independant, content: <p className="os-body1 auth-img-text ">Un <span className="important">contrôle total de votre clientèle</span>. <br/>Openstone ne sollicite en aucun cas vos clients</p>},
  ]

  async function fetchUser() {
    await authenticationStore.loadCgpUserData().then(response => {
      setUser(response);
      setLoading(false);
    });
  }

  useEffect(() => {
    otp && fetchUser();
  }, [otp]);

  useEffect(() => {
    if (!otp && authToken && authToken !== 'undefined') {
      navigate('/sign-up/cgp/documents')
    }
  }, [authToken, navigate]);

  return (
    <>
      <Helmet>
        <title>Inscription partenaires et cgp</title>
      </Helmet>
      <PublicNav />
      <div className="os-flex collab-container">
        <div className="collab-left-container  image-section-sign-up">
          <div className="sub-container">
            <h4 className="os-h4" style={{color:"white"}}>Utilisez Openstone pour la gestion de vos clients</h4>
            <div className="avantages-container">
              {elements.map((e, index) => (
                <div className="os-flex avantage-element" key={index}>
                  <img src={e.img} alt=""/>
                  <span className="os-body2">
                    {e.content}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="collab-auth-container" style={{marginTop :'40px'}}>
            <LogoGreen className="auth-os-logo"/>

          <div className="collab-auth-card">
              {otp ? (
                  <>
                    <h4 className="os-h4">Vérifier votre numéro de téléphone</h4>
                    <span className="os-caption signup-text">Pour la sécurité de votre compte, veuillez vérifier votre numéro de de téléphone</span>
                    <Phone user={user} page="signUp" recordType="cgp" />
                  </>
              ) : (
                  <>
                    <div className="os-flex__column top-container" style={{justifyContent: "space-between"}}>
                      <h4 className="os-h4">Inscrivez-vous sur Openstone</h4>
                      <div className="os-flex top-form">
                        <span className="os-caption signup-text align-right">Déjà inscrit ?
                          <Link to="/sign-in/cgp" className="signup-link"> Connectez-vous !</Link>
                        </span>
                        <OSButton variant="primary-full" size="large" title="Vous êtes un particulier ?" fit link="/sign-up" icon={<ArrowForwardIcon />} position="end"/>
                      </div>
                    </div>
                    <p className="os-subtitle1">Vos informations</p>
                    <CgpSignUpForm setPhone={setOtp}/>
                  </>
              )}
          </div>
          <div className="labels-signup" style={{width:'60%'}}>
            <img src={images.brand.labels} alt="Labels Openstone"/>
          </div>
        </div>
      </div>
	    <Footer />
    </>
  );
};

export default CgpSignUp;
